import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './fashion.css';


export class Fashion extends Component {
    render() {
        return (
            <div className="home">
                <div className="section design">
                    <div className="section title-text">
                        <img className="pageTitle" id="fashionTitle" src="fashionTitle.png"></img>
                        <a>Check out some of the clothing I've made.</a>
                    </div>

                
                    <div className="section flex lightbox">
                        {/* <img className="pageLight" id="fashionLight" src="fashionLight.png"></img> */}
                        <div className="lightbar purple leftbar"></div>
                        <div className="section flex cards">
                            <div className="tilt">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card purple-background">
                                    <div className="card-title">
                                        <p className="project-title">Psyduck Bucket Hat</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="testCard" src="psyduck2.jpg"></img>
                                    <div className="card-text">
                                        {/* <a>This is a placeholder sentence for a project description.</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="tilt">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card purple-background">
                                    <div className="card-title">
                                        <p className="project-title contrast">Third Culture Jacket</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="testCard" src="gecko3.jpg"></img>
                                    <div className="card-text">
                                        {/* <a>This is a placeholder sentence for a project description.</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="tilt">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card purple-background">
                                    <div className="card-title">
                                        <p className="project-title">Sunset Denim</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="testCard" src="denim+.jpg"></img>
                                    <div className="card-text">
                                        {/* <a>This is a placeholder sentence for a project description.</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <img className="pageLight" id="fashionLight" src="fashionLight.png"></img> */}
                        <div className="lightbar purple rightbar"></div>
                    </div>
                </div>   
            </div>
        )
    }
}

export default Fashion
