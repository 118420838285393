import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './design.css';


export class Design extends Component {
    render() {
        return (
                <div className="home">
                    <div className="section design">
                        <div className="section title-text">
                            <img className="pageTitle" id="designTitle" src="designTitle.png"></img>
                            {/* <a>These are some of my design projects.</a> */}
                        </div>

                        <div className="section flex lightbox">
                            {/* <img className="pageLight" id="designLight" src="designLight.png"></img> */}
                            <div className="lightbar blue leftbar"></div>
                            
                            <div className="section flex cards">
                                <Link to='/projects/swoveralls-cart'>
                                    <div className="tilt">
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <div className="card blue-background">
                                            <div className="card-title">
                                                <p className="project-title">Swoveralls Cart Redesign</p>
                                                <p className="project-date">2024</p>
                                            </div>
                                            <img className="card-image" id="testCard" src="swoveralls.jpg"></img>
                                            <div className="card-text">
                                                <a className="ux-design">UI/UX</a>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link to='/projects/spirit'>
                                    <div className="tilt">
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <div className="card blue-background">
                                            <div className="card-title">
                                                <p className="project-title">Spirit</p>
                                                <p className="project-date">2022</p>
                                            </div>
                                            <img className="card-image" id="testCard" src="havit.png"></img>
                                            <div className="card-text">
                                                <a className="product-design">Product</a>
                                                <a className="ux-design">UI/UX</a>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                
                                <Link to='/projects/lighten'>
                                    <div className="tilt">
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <div className="card blue-background">
                                            <div className="card-title">
                                                <p className="project-title">Lighten</p>
                                                <p className="project-date">2022</p>
                                            </div>
                                            <img className="card-image" id="testCard" src="lighten.jpg"></img>
                                            <div className="card-text">
                                                <a className="brand-design">Brand</a>
                                                <a className="product-design">Product</a>
                                                {/* <a className="ux-design">UI/UX</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link to='/projects/thinkingcap'>
                                    <div className="tilt">
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <span className="t_over"></span>
                                        <div className="card blue-background">
                                            <div className="card-title">
                                                <p className="project-title">Coding Storybooks</p>
                                                <p className="project-date">2022</p>
                                            </div>
                                            <img className="card-image" id="testCard" src="thinkingCapLogo.png"></img>
                                            <div className="card-text">
                                                <a className="brand-design">Brand</a>
                                                <a className="illustration-design">Illustration</a>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link to='/projects/spotify'>
                                <div className="tilt">
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <div className="card blue-background">
                                        <div className="card-title">
                                            <p className="project-title">Music Inbox</p>
                                            <p className="project-date">2021</p>
                                        </div>
                                        <img className="card-image" id="testCard" src="spotify-4.jpg"></img>
                                        <div className="card-text">
                                            <a className="product-design">Product</a>
                                            <a className="ux-design">UI/UX</a>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            
                            {/* <img className="pageLight" id="designLight" src="designLight.png"></img> */}
                            <div className="lightbar blue rightbar"></div>
                        </div>
                    </div>
                    
                </div>
        )
    }
}

export default Design
