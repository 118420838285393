import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './concerts.css';
import {SRLWrapper} from "simple-react-lightbox";
import august1 from './music/august1.jpg';
import august2 from './music/august2.jpg';
import august3 from './music/august3.jpg';
import baynk1 from './music/baynk1.jpg';
import baynk2 from './music/baynk2.jpg';
import comatose1 from './music/comatose1.jpg';
import comatose2 from './music/comatose2.jpg';
import goody1 from './music/goody1.jpg';
import jakubi1 from './music/jakubi1.jpg';
import jesse1 from './music/jesse1.jpg';
import limbo1 from './music/limbo1.jpg';
import limbo2 from './music/limbo2.jpg';
import mch1 from './music/mch1.jpg';
import minimansions1 from './music/minimansions1.jpg';
import mnek1 from './music/mnek1.jpg';
import mnek2 from './music/mnek2.jpg';
import moonchild1 from './music/moonchild1.jpg';
import moonchild2 from './music/moonchild2.jpg';
import moonchild3 from './music/moonchild3.jpg';
import neonindian1 from './music/neonindian1.jpg';
import neonindian2 from './music/neonindian2.jpg';
import neonindian3 from './music/neonindian3.jpg';
import nombe1 from './music/nombe1.jpeg';
import nombe2 from './music/nombe2.jpeg';
import ritchie1 from './music/ritchie1.jpg';
import riz1 from './music/riz1.jpg';
import riz2 from './music/riz2.jpg';
import riz3 from './music/riz3.jpg';
import riz4 from './music/riz4.jpg';
import riz5 from './music/riz5.jpg';
import riz6 from './music/riz6.jpg';
import riz7 from './music/riz7.jpg';
import riz8 from './music/riz8.jpg';
import riz9 from './music/riz9.jpg';
import saiah1 from './music/saiah1.jpg';
import saiah2 from './music/saiah2.jpg';
import sampa1 from './music/sampa1.jpg';
import sampa2 from './music/sampa2.jpg';
import sampa3 from './music/sampa3.jpg';
import sampa4 from './music/sampa4.jpg';
import sampa5 from './music/sampa5.jpg';
import sampa6 from './music/sampa6.jpg';
import shae2 from './music/shae2.jpg';
import zhu1 from './music/zhu1.jpg';
import zhu2 from './music/zhu2.jpg';
import zhu3 from './music/zhu3.jpg';
import zhu4 from './music/zhu4.jpg';


export class Concerts extends Component {
    render() {
        return (
                <div className="gallery">
                    <SRLWrapper>
                            <img id="baynk1" src={baynk1} alt="BAYNK"></img>
                            <img id="goody1" src={goody1} alt="Goody Grace"></img>
                            <img id="nombe1" src={nombe1} alt="NoMBe"></img>
                            <img id="shae2" src={shae2} alt="Shae Brock"></img>
                            <img id="sampa3" src={sampa3} alt="Sampa the Great"></img>
                            <img id="august3" src={august3} alt="August 08"></img>
                            <img id="august1" src={august1} alt="August 08"></img>
                            <img id="august2" src={august2} alt="August 08"></img>
                            <img id="zhu4" src={zhu4} alt="ZHU"></img>
                            <img id="nombe2" src={nombe2} alt="NoMBe"></img>
                            <img id="neonindian3" src={neonindian3} alt="Neon Indian"></img>
                            <img id="mnek2" src={mnek2} alt="MNEK"></img>
                            <img id="neonindian2" src={neonindian2} alt="Neon Indian"></img>
                            <img id="ritchie1" src={ritchie1} alt="Raleigh Ritchie"></img>
                            <img id="jakubi1" src={jakubi1} alt="Jakubi"></img>
                            <img id="mch1" src={mch1} alt="Magic City Hippies"></img>
                            <img id="comatose1" src={comatose1} alt="Brothers Comatose"></img>
                            <img id="comatose2" src={comatose2} alt="Brothers Comatose"></img>
                            <img id="limbo1" src={limbo1} alt="Limbo"></img>
                            <img id="limbo2" src={limbo2} alt="Limbo"></img>
                            <img id="mnek1" src={mnek1} alt="MNEK"></img>
                            <img id="baynk2" src={baynk2} alt="BAYNK"></img>
                            <img id="minimansions1" src={minimansions1} alt="Mini Mansions"></img>
                            <img id="neonindian1" src={neonindian1} alt="Neon Indian"></img>
                            <img id="zhu1" src={zhu1} alt="ZHU"></img>
                            <img id="jesse1" src={jesse1} alt="Jesse"></img>
                            <img id="moonchild1" src={moonchild1} alt="Moonchild"></img>
                            <img id="moonchild2" src={moonchild2} alt="Moonchild"></img>
                            <img id="riz1" src={riz1} alt="RIZ LA VIE"></img>
                            <img id="riz2" src={riz2} alt="RIZ LA VIE"></img>
                            <img id="riz3" src={riz3} alt="RIZ LA VIE"></img>
                            <img id="moonchild3" src={moonchild3} alt="Moonchild"></img>
                            <img id="riz8" src={riz8} alt="RIZ LA VIE"></img>
                            <img id="riz9" src={riz9} alt="RIZ LA VIE"></img>
                            <img id="saiah1" src={saiah1} alt="SAIAH"></img>
                            <img id="saiah2" src={saiah2} alt="SAIAH"></img>
                            <img id="zhu2" src={zhu2} alt="ZHU"></img>
                            <img id="riz4" src={riz4} alt="RIZ LA VIE"></img>
                            <img id="riz5" src={riz5} alt="RIZ LA VIE"></img>
                            <img id="riz6" src={riz6} alt="RIZ LA VIE"></img>
                            <img id="riz7" src={riz7} alt="RIZ LA VIE"></img>
                            <img id="sampa1" src={sampa1} alt="Sampa the Great"></img>
                            <img id="sampa2" src={sampa2} alt="Sampa the Great"></img>
                            <img id="sampa4" src={sampa4} alt="Sampa the Great"></img>
                            <img id="sampa5" src={sampa5} alt="Sampa the Great"></img>
                            <img id="sampa6" src={sampa6} alt="Sampa the Great"></img>
                            <img id="zhu3" src={zhu3} alt="ZHU"></img>
                            
                    </SRLWrapper>
                </div>
        )
    }
}

export default Concerts