import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './borrego.css';
import {SRLWrapper} from "simple-react-lightbox";
import bs1 from './borrego/bs1.jpg';
import bs2 from './borrego/bs2.jpg';
import bs3 from './borrego/bs3.jpg';
import bs4 from './borrego/bs4.jpg';
import bs5 from './borrego/bs5.jpg';
import bs6 from './borrego/bs6.jpg';
import bs7 from './borrego/bs7.jpg';
import bs8 from './borrego/bs8.jpg';
import bs9 from './borrego/bs9.jpg';
import bs10 from './borrego/bs10.jpg';
import bs11 from './borrego/bs11.jpg';
import bs12 from './borrego/bs12.jpg';
import bs13 from './borrego/bs13.jpg';
import bs14 from './borrego/bs14.jpg';
import bs15 from './borrego/bs15.jpg';
import bs16 from './borrego/bs16.jpg';
import bs17 from './borrego/bs17.jpg';
import bs18 from './borrego/bs18.jpg';
import bs19 from './borrego/bs19.jpg';
import bs20 from './borrego/bs20.jpg';
import bs21 from './borrego/bs21.jpg';
import bs22 from './borrego/bs22.jpg';
import bs23 from './borrego/bs23.jpg';
import bs24 from './borrego/bs24.jpg';
import bs25 from './borrego/bs25.jpg';
import bs26 from './borrego/bs26.jpg';
import bs27 from './borrego/bs27.jpg';
import bs28 from './borrego/bs28.jpg';
import bs29 from './borrego/bs29.jpg';





export class Borrego extends Component {
    render() {
        return (
                <div className="gallery">
                    <SRLWrapper>
                        <img id="bs1" src={bs1}></img>
                        <img id="bs2" src={bs2}></img>
                        <img id="bs3" src={bs3}></img>
                        <img id="bs4" src={bs4}></img>
                        <img id="bs5" src={bs5}></img>
                        <img id="bs6" src={bs6}></img>
                        <img id="bs7" src={bs7}></img>
                        <img id="bs8" src={bs8}></img>
                        <img id="bs9" src={bs9}></img>
                        <img id="bs10" src={bs10}></img>
                        <img id="bs11" src={bs11}></img>
                        <img id="bs12" src={bs12}></img>
                        <img id="bs13" src={bs13}></img>
                        <img id="bs14" src={bs14}></img>
                        <img id="bs15" src={bs15}></img>
                        <img id="bs16" src={bs16}></img>
                        <img id="bs17" src={bs17}></img>
                        <img id="bs18" src={bs18}></img>
                        <img id="bs19" src={bs19}></img>
                        <img id="bs20" src={bs20}></img>
                        <img id="bs21" src={bs21}></img>
                        <img id="bs22" src={bs22}></img>
                        <img id="bs23" src={bs23}></img>
                        <img id="bs24" src={bs24}></img>
                        <img id="bs25" src={bs25}></img>
                        <img id="bs26" src={bs26}></img>
                        <img id="bs27" src={bs27}></img>
                        <img id="bs28" src={bs28}></img>
                        <img id="bs29" src={bs29}></img>
                    </SRLWrapper>
                </div>
        )
    }
}

export default Borrego