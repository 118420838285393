import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './swoveralls.css';
import {SRLWrapper} from "simple-react-lightbox";
import rsp1 from './swoveralls/DSC03717.jpg';
import rsp2 from './swoveralls/DSC03772.jpg';
import rsp3 from './swoveralls/DSC03916.jpg';
import rsp4 from './swoveralls/DSC04268.jpg';
import rsp5 from './swoveralls/DSC04025.jpg';
import rsp6 from './swoveralls/DSC04079.jpg';
import rsp7 from './swoveralls/DSC04312.jpg';
import rsp8 from './swoveralls/DSC04485.jpg';
import rsp9 from './swoveralls/DSC04529.jpg';
import rsp10 from './swoveralls/DSC04583.jpg';
import rsp11 from './swoveralls/DSC04619.jpg';
import rsp13 from './swoveralls/DSC04698.jpg';
import rsp12 from './swoveralls/DSC04495.jpg';
import malav1 from './swoveralls/SV1_1186.jpg';
import malav2 from './swoveralls/SV1_1234.jpg';
import malav3 from './swoveralls/SV1_1399.jpg';
import malav4 from './swoveralls/SV1_1419.jpg';
import malav5 from './swoveralls/SV1_1568.jpg';
import malav6 from './swoveralls/SV1_1674.jpg';
import malav7 from './swoveralls/SV1_1770.jpg';
import malav8 from './swoveralls/SV1_1797.jpg';
import rt1 from './swoveralls/DSC03063.jpg';
import rt2 from './swoveralls/DSC03113.jpg';
import rt3 from './swoveralls/DSC03195.jpg';
import rt4 from './swoveralls/DSC03215.jpg';
import rt5 from './swoveralls/DSC03265.jpg';
import rt6 from './swoveralls/DSC03373.jpg';
import pc1 from './swoveralls/DSC_0031.jpg';
import pc2 from './swoveralls/DSC_0290.jpg';
import pc3 from './swoveralls/DSC_0384.jpg';
import pc4 from './swoveralls/DSC_0472.jpg';
import pc5 from './swoveralls/DSC_0729.jpg';
import pc6 from './swoveralls/DSC_0975.jpg';
import pc7 from './swoveralls/DSC_0006.jpg';
import pc8 from './swoveralls/DSC_0116.jpg';
import pc9 from './swoveralls/DSC_0052.jpg';
import polartec1 from './swoveralls/DSC_0094.jpg';
import polartec2 from './swoveralls/DSC_0209.jpg';
import polartec3 from './swoveralls/DSC_0178.jpg';
import polartec4 from './swoveralls/DSC_0238.jpg';
import polartec5 from './swoveralls/DSC_0243.jpg';
import polartec6 from './swoveralls/DSC_0767.jpg';
import polartec7 from './swoveralls/DSC_0904.jpg';
import polartec8 from './swoveralls/DSC_0977.jpg';
import polartec9 from './swoveralls/DSC_0289.jpg';




export class Swoveralls extends Component {
    render() {
        return (
                <div className="gallery">
                    <SRLWrapper>
                        <img className="full-width" id="rsp1" src={rsp1} alt="Retro Summer Print Collection 2024"></img>
                        <img id="rsp2" src={rsp2} alt="Retro Summer Print Collection 2024"></img>
                        <img id="rsp3" src={rsp3} alt="Retro Summer Print Collection 2024"></img>
                        <img id="rsp4" src={rsp4} alt="Retro Summer Print Collection 2024"></img>
                        <img id="rsp5" src={rsp5} alt="Retro Summer Print Collection 2024"></img>
                        <img id="rsp6" src={rsp6} alt="Retro Summer Print Collection 2024"></img>
                        <img id="rsp7" src={rsp7} alt="Retro Summer Print Collection 2024"></img>
                        <img id="rsp8" src={rsp8} alt="Retro Summer Print Collection 2024"></img>
                        <img id="rsp9" src={rsp9} alt="Retro Summer Print Collection 2024"></img>
                        <img id="rsp10" src={rsp10} alt="Retro Summer Print Collection 2024"></img>
                        <img id="rsp11" src={rsp11} alt="Retro Summer Print Collection 2024"></img>
                        <img id="rsp13" src={rsp13} alt="Retro Summer Print Collection 2024"></img>
                        <img className="full-width" id="rsp12" src={rsp12} alt="Retro Summer Print Collection 2024"></img>
                        <img id="malav1" src={malav1} alt="Matcha Lavender Collection 2024"></img>
                        <img id="malav2" src={malav2} alt="Matcha Lavender Collection 2024"></img>
                        <img id="malav3" src={malav3} alt="Matcha Lavender Collection 2024"></img>
                        <img id="malav4" src={malav4} alt="Matcha Lavender Collection 2024"></img>
                        <img id="malav5" src={malav5} alt="Matcha Lavender Collection 2024"></img>
                        <img id="malav6" src={malav6} alt="Matcha Lavender Collection 2024"></img>
                        <img id="malav7" src={malav7} alt="Matcha Lavender Collection 2024"></img>
                        <img id="malav8" src={malav8} alt="Matcha Lavender Collection 2024"></img>
                        <img id="rt1" src={rt1} alt="Retro French Terry Collection 2024"></img>
                        <img id="rt2" src={rt2} alt="Retro French Terry Collection 2024"></img>
                        <img id="rt3" src={rt3} alt="Retro French Terry Collection 2024"></img>
                        <img id="rt4" src={rt4} alt="Retro French Terry Collection 2024"></img>
                        <img id="rt5" src={rt5} alt="Retro French Terry Collection 2024"></img>
                        <img id="rt6" src={rt6} alt="Retro French Terry Collection 2024"></img>
                        <img id="pc1" src={pc1} alt="Print Collection 2024"></img>
                        <img id="pc2" src={pc2} alt="Print Collection 2024"></img>
                        <img id="pc3" src={pc3} alt="Print Collection 2024"></img>
                        <img id="pc4" src={pc4} alt="Print Collection 2024"></img>
                        <img id="pc5" src={pc5} alt="Print Collection 2024"></img>
                        <img id="pc6" src={pc6} alt="Print Collection 2024"></img>
                        <img id="pc7" src={pc7} alt="Print Collection 2024"></img>
                        <img id="pc8" src={pc8} alt="Print Collection 2024"></img>
                        <img id="pc9" src={pc9} alt="Print Collection 2024"></img>
                        <img id="polartec1" src={polartec1} alt="Polartec Collection 2023"></img>
                        <img id="polartec2" src={polartec2} alt="Polartec Collection 2023"></img>
                        <img id="polartec3" src={polartec3} alt="Polartec Collection 2023"></img>
                        <img id="polartec4" src={polartec4} alt="Polartec Collection 2023"></img>
                        <img id="polartec5" src={polartec5} alt="Polartec Collection 2023"></img>
                        <img id="polartec6" src={polartec6} alt="Polartec Collection 2023"></img>
                        <img id="polartec7" src={polartec7} alt="Polartec Collection 2023"></img>
                        <img id="polartec8" src={polartec8} alt="Polartec Collection 2023"></img>
                        <img className="full-width" id="polartec9" src={polartec9} alt="Polartec Collection 2023"></img>

                    </SRLWrapper>
                </div>
        )
    }
}

export default Swoveralls