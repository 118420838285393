import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './musicInbox/Montserrat/Montserrat-Bold.ttf';
import './musicInbox/Montserrat/Montserrat-Regular.ttf';
import './musicInbox.css';
import musicInboxHeader from './musicInbox/musinboxhead.png';
import musicInboxBg from './musicInbox/musinboxbg.png';
import musicHeadBack from './musicInbox/musicheadback.png';
import spotifyData from './musicInbox/spotifyData.png';
import solution from './musicInbox/solution.png';
import lofi from './musicInbox/lofi.png';
import hifi from './musicInbox/hifi.png';
import hifiProto from './musicInbox/hifiProto.png';

export class MusicInbox extends Component {

    render() {


        return (
                <div className="musicInbox">
                    <div className="title">
                        <h2>Music Inbox</h2>
                    </div>
                    
                    <div className="sec"> 
                        <p>
                            <b>Overview</b>
                        </p>
                        <br></br>
                        <a>
                            An unofficial feature for Spotify which allows users to view music shared by friends. This would enable users to share music directly to friends’ profiles and view an inbox of all the songs, albums, and playlists sent to them by their friends without leaving the app. 
                        </a>
                        <br></br>
                        <br></br>
                    </div>
                    <div className="sec"> 
                        <p>
                            <b>The Problem</b>
                        </p>
                        <br></br>
                        <a>
                            Around 75% of surveyed responses said they often forget to listen to music shared by their friends because they don’t listen to the music right away. The cause of this problem is due to the primary method of sending music being texting links and screenshots or sharing via word of mouth. If the recipient is not listening to music when a friend recommends a song, it is common for them to wait until they are actually listening to check out the sent music. The longer the delay, the more likely the recipient is to forget to open the text to find the song.
                        </a>
                        <br></br>
                        
                        <img className="img-inbox" id="spotifyData" src={spotifyData}></img>
                        
                        <br></br>
                    </div>
                    <div className="sec"> 
                        <p>
                            <b>The Solution</b>
                        </p>
                        <br></br>
                        <a>
                            This problem can be avoided entirely by having a notification on the home page which alerts the user that someone has sent them music. This way, when the recipient feels like listening to music, they will immediately see what their friends think they should listen to. The Music Inbox will add a more social element to Spotify that isn’t present in other music streaming platforms. This feature could help develop a culture of sharing playlists and allow the users to shape the music listening experience for each other. 
                        </a>
                        <br></br>
                        <img className="img-inbox" id="solution" src={solution}></img>
                        <br></br>
                    </div>
                    <div className="sec"> 
                        <p>
                            <b>Design</b>
                        </p>
                        <br></br>
                        <a>
                            <b>Lo-Fi Wireframing and Logo Design:</b>
                        </a>
                        <br></br>
                        <a>
                            The challenge for this feature was to create an icon that clearly represents shared music and is seamless with Spotify’s existing interface. The icons currently used are simple and efficient in their depiction of a concept. The icon that surveyed people consistently recognized as shared music was the icon that depicted two people being connected with a music note. It works for this purpose because it uses minimal lines and can be recognized even at a small size.
                        </a>
                        <br></br>
                        <img className="img-inbox" id="lofi" src={lofi}></img>
                        <br></br>
                        <a>
                            <b>High Fidelity Prototype:</b>
                        </a>
                        <br></br>
                        <a>
                            The new pages added to the existing Spotify interface are the “Shared with you” page (which acts as the music inbox feature) and the “Share to profile” option in the share tab which allows the user to send music directly to another user’s profile. These two pages are all that are required to make this a fully functioning feature. 
                        </a>
                        <br></br>
                        
                        <img className="img-inbox" id="hifi" src={hifi}></img>
                        
                        <br></br>
                       
                        <img className="img-inbox" id="hifiProto" src={hifiProto}></img>
                        
                        <br></br>
                    </div>
                    <div className="sec"> 
                        <p>
                            <b>Summary</b>
                        </p>
                        <br></br>
                        <a>
                            The music inbox makes it convenient for the user to send and listen to shared music. This will also improve the user’s experience by preventing them from forgetting to listen to their friends’ recommended music and better connecting with their friends. The design aims to seamlessly integrate with the existing Spotify app and be intuitive for existing and new users.
                        </a>
                        <br></br>
                        <br></br>
                    </div>
                </div>
        )
    }
}

export default MusicInbox