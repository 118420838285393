import React, {Component} from "react";
import './home.css';
import { Link } from 'react-router-dom'

export class Home extends Component {  

    render(){
        return(
            <div className="home">
                <div className="section intro">
                    <div className="section flex spaced">
                        <div className="section flex text">
                            <img id="nameTitle" src="nameTitle.png"></img>
                            {/* <p>Product Designer with a mission to inspire <br></br> <span className="greenText">Empathy</span>, <span className="blueText">Confidence</span>, and <span className="purpleText">Mindfulness</span></p> */}
                        </div>
                        <div className="section flex image">
                            <img id="threeLights" src="threeLights.png"></img>
                        </div>
                    </div>
                </div>
                <div className="section projects">
                    <img id="projectsTitle" src="projectsTitle.png"></img>
                    <div className="section flex lightbox">
                        {/* <img id="projectLight" src="projectLight.png"></img> */}
                        <div className="home-lightbar green"></div>
                        <div className="section flex cards">
                            <Link to='/projects/spirit'>
                                <div className="tilt">
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <div className="card green-background">
                                        <div className="card-title">
                                            <p className="project-title">Spirit</p>
                                            <p className="project-date">2022</p>
                                        </div>
                                        <img className="card-image" id="testCard" src="havit.png"></img>
                                        <div className="card-text">
                                            <a className="product-design">Product</a>
                                            <a className="ux-design">UI/UX</a>
                                        </div>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/projects/lighten'>
                                <div className="tilt">
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <div className="card green-background">
                                        <div className="card-title">
                                            <p className="project-title">Lighten</p>
                                            <p className="project-date">2022</p>
                                        </div>
                                        <img className="card-image" id="testCard" src="lighten.jpg"></img>
                                        <div className="card-text">
                                            <a className="brand-design">Brand</a>
                                            <a className="product-design">Product</a>
                                        </div>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/projects/spotify'>
                                <div className="tilt">
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <div className="card green-background">
                                        <div className="card-title">
                                            <p className="project-title">Music Inbox</p>
                                            <p className="project-date">2021</p>
                                        </div>
                                        <img className="card-image" id="testCard" src="spotify-4.jpg"></img>
                                        <div className="card-text">
                                            <a className="product-design">Product</a>
                                            <a className="ux-design">UI/UX</a>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    
                </div>
                <div className="section projects">
                    <img id="aboutTitle" src="aboutTitle.png"></img>
                    <div className="section flex lightbox">
                        {/* <img id="designLight" src="designLight.png"></img> */}
                        <div className="home-lightbar blue"></div>
                        <div className="section flex about">
                            <a> I believe that your life is the sum of all the experiences you get to encounter. Every bite of food, every person you meet, or every product designer's aesthetic, personal website you have the pleasure of visiting is an experience that can help you grow if you let it. Fear is the biggest obstacle for seeking out and learning from new experiences, though. I dream of designing experiences that help people fight their fear by inspiring mindfulness, confidence, and empathy.</a>
                            <br></br>
                            <a><span className="purpleText"><b>Mindfulness</b></span> allows you to recognize your fears and find opportunities for new experiences.</a>
                            <br></br>
                            <a><span className="blueText"><b>Confidence</b></span> enables you to push past your fears and face any challenge or experience head on.</a>
                            <br></br>
                            <a><span className="greenText"><b>Empathy</b></span> lets you understand another person's experience and share one with them.</a>
                            <br></br>
                            <a>As a self-taught, multidisciplinary designer, I take pride in using these principles to find unique solutions and inspiration from the world around me.   </a>
                        </div>
                    </div>
                </div>
                <div className="section projects">
                    <img id="contactTitle" src="contactTitle.png"></img>
                    <div className="section flex contact">
                        {/* <img id="fashionLight" src="fashionLight.png"></img> */}
                        <div className="home-lightbar purple"></div>
                        <div className="section flex about">
                            <a> I am currently looking to embark on new adventures. If you are interested in working with me, you can view my resume below and contact me via email.</a>
                            <br></br>
                            <a className="purpleTextHover" href="ShivaVResume2024.pdf" target="_blank"><u>Resume</u></a>
                            <a className="purpleTextHover" href="mailto: shivatejav@gmail.com" >shivatejav@gmail.com</a>
                            <br></br>
                            <br></br>
                            <a>Check out my Instagram accounts:</a>
                            <a className="greenTextHover" href="https://www.instagram.com/shivatejav/?hl=en" target="_blank">@shivatejav</a>
                            <a className="blueTextHover" href="https://www.instagram.com/svshoots/?hl=en" target="_blank">@svshoots</a>
                            <a className="purpleTextHover" href="https://www.instagram.com/designed.by.shiva/?hl=en" target="_blank">@designed.by.shiva</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;