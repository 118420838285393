import React, {Component} from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import Route from 'react-router-dom/Route';
// import Gallery from 'react-photo-gallery';
import {SRLWrapper} from "simple-react-lightbox";
import './App.css';
import Header from './components/header.js';
import Home from './components/home.js';

import Design from './components/pages/design.js';
  import MusicInbox from './components/pages/design/musicInbox.js';
  import ThinkingCap from './components/pages/design/thinkingcap.js';
  import Lighten from './components/pages/design/lighten.js';
  import Havit from './components/pages/design/havit.js';
  import Moonwalkers from './components/pages/design/moonwalkers-lp.js';
  import SwovCart from './components/pages/design/swov-cart.js';
  import SwovGift from './components/pages/design/swov-gift.js';
  import SwovLP from './components/pages/design/swov-lp.js';
  import SwovMarketing from './components/pages/design/swov-marketing.js';
  import SwovPins from './components/pages/design/swov-pins.js';
import Art from './components/pages/art.js';
  import Treehouse from './components/pages/art/treehouse.js';
  import Homeandcity from './components/pages/art/homeandcity.js';
  import Memory from './components/pages/art/memory';
  import Misc from './components/pages/art/misc.js';
import Fashion from './components/pages/fashion.js';
  import DuckCamo from './components/pages/Fashion/duckCamo.js';
  import EgoDeath from './components/pages/Fashion/egoDeath.js';
  import Festivalls from './components/pages/Fashion/festivalls.js';
  import Gecko from './components/pages/Fashion/gecko.js';
  import Kage from './components/pages/Fashion/kage.js';
  import Psyduck from './components/pages/Fashion/psyduck.js';
  import RetroSummer from './components/pages/Fashion/retroSummer.js';
  import SunsetDenim from './components/pages/Fashion/sunsetDenim.js';
import Photo from './components/pages/photo.js';
  import Concerts from './components/pages/photo/concerts.js';
  import Wildlife from './components/pages/photo/wildlife.js';
  import Hawaii from './components/pages/photo/hawaii.js';
  import Nike from './components/pages/photo/nike.js';
  import Borrego from './components/pages/photo/borrego.js';
  import Swoveralls from './components/pages/photo/swoveralls.js';
import Video from './components/pages/video.js';
import Music from './components/pages/music.js';
import Playlist from './components/pages/playlist.js';
import Stories from './components/pages/stories.js';


class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Header/>
            <Route exact path='/' render={props => (
                <Home />
            )} />
            <Route exact path='/projects'>
              <Design />
            </Route>
              <Route exact path='/projects/spotify'>
                <MusicInbox />
              </Route>
              <Route exact path='/projects/thinkingcap'>
                <ThinkingCap />
              </Route>
              <Route exact path='/projects/lighten'>
                <Lighten />
              </Route>
              <Route exact path='/projects/spirit'>
                <Havit />
              </Route>
              <Route exact path='/projects/moonwalkers'>
                <Moonwalkers />
              </Route>
              <Route exact path='/projects/swoveralls-cart'>
                <SwovCart />
              </Route>
              <Route exact path='/projects/swoveralls-gift-card'>
                <SwovGift />
              </Route>
              <Route exact path='/projects/swoveralls-landing-pages'>
                <SwovLP />
              </Route>
              <Route exact path='/projects/swoveralls-marketing'>
                <SwovMarketing />
              </Route>
              <Route exact path='/projects/swoveralls-pins'>
                <SwovPins />
              </Route>
            <Route exact path='/art'>
              <Art />
            </Route>
              <Route exact path='/art/treehouse'>
                <Treehouse />
              </Route>
              <Route exact path='/art/homeandcity'>
                <Homeandcity />
              </Route>
              <Route exact path='/art/memory'>
                <Memory />
              </Route>
              <Route exact path='/art/misc'>
                <Misc />
              </Route>
            <Route exact path='/fashion'>
              <Fashion />
            </Route>
              <Route exact path='/fashion/sunset-denim-jacket'>
                <SunsetDenim />
              </Route>
              <Route exact path='/fashion/psyduck-bucket-hat'>
                <Psyduck />
              </Route>
              <Route exact path='/fashion/ego-death-denim-jacket'>
                <EgoDeath />
              </Route>
              <Route exact path='/fashion/kage-sling-bag'>
                <Kage />
              </Route>
              <Route exact path='/fashion/gecko-jacket'>
                <Gecko />
              </Route>
              <Route exact path='/fashion/festivalls'>
                <Festivalls />
              </Route>
              <Route exact path='/fashion/retro-summer-swoveralls'>
                <RetroSummer />
              </Route>
              <Route exact path='/fashion/duck-camo-swoveralls'>
                <DuckCamo />
              </Route>
            <Route exact path='/photo'>
              <Photo />
            </Route>
              <Route exact path='/photo/concerts'>
                <Concerts />
              </Route>
              <Route exact path='/photo/wildlife'>
                <Wildlife />
              </Route>
              <Route exact path='/photo/swoosh'>
                <Nike />
              </Route>
              <Route exact path='/photo/hawaii'>
                <Hawaii />
              </Route>
              <Route exact path='/photo/borrego'>
                <Borrego />
              </Route>
              <Route exact path='/photo/swoveralls'>
                <Swoveralls />
              </Route>
            <Route exact path='/video'>
              <Video />
            </Route>
            <Route exact path='/music'>
              <Music />
            </Route>
            <Route exact path='/playlists'>
              <Playlist />
            </Route>
            <Route exact path='/stories'>
              <Stories />
            </Route>
  
        </div>
      </Router>
    );
  } 
}

export default App;
