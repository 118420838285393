import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './musicInbox/Montserrat/Montserrat-Bold.ttf';
import './musicInbox/Montserrat/Montserrat-Regular.ttf';
import './musicInbox.css';
import './lighten.css';
import './thinkingcap.css';

import blastoff from './thinkingCap/Blastoff-mockup.png';
import countdown from './thinkingCap/countdown-mockup.png';

export class ThinkingCap extends Component {

    render() {


        return (
                <div className="thinkingCapPage">
                    <div className="title">
                        <h2>Coding Storybooks</h2>
                    </div>
                    <br></br>
                    <a className="sec centered">
                    Working with Thinking Cap, a Toronto-based startup aiming to make learning to code easy and enjoyable, I was tasked with building a concept for interactive, educational storybooks. These storybooks, while functioning as educational workbooks, build in lessons under the guise of missions that are relevant to the story. My role consisted of creating the concept for these stories, designing potential book covers, and aiding in the creation of a consistent branding guide. 
                    </a>
                    <div className='sec-split'>
                        <img className="img-thinkingcap" id="countdown" src={countdown}></img>
                        <br></br>
                        <br></br>
                        <img className="img-thinkingcap" id="blastoff" src={blastoff}></img>
                        <br></br>
                        <br></br>
                    </div>
                    
                   
                   
                </div>
        )
    }
}

export default ThinkingCap