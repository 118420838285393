import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './playlist.css';


export class Playlist extends Component {
    render() {
        return (
            <div className="home">
                <div className="section design">
                    <div className="section title-text">
                        <img className="pageTitle" id="playlistTitle" src="playlistTitle.png"></img>
                        <a>Check out my Spotify playlists.</a>
                    </div>

                
                    <div className="section flex lightbox">
                        {/* <img className="pageLight" id="playlistLight" src="playlistLight.png"></img> */}
                        <div className="lightbar pink leftbar"></div>
                        <div className="section flex cards">
                            
                            <div className="tilt" href="https://open.spotify.com/playlist/5O72pAJoOfJSKcKsy1GgrT?si=10649d3d1ed7467f">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card rose-background">
                                    <div className="card-title">
                                        <p className="project-title">Dreamscape</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="dreamscapeCard" src="dreamscape.png"></img>
                                    <div className="card-text">
                                        {/* <a>This is a placeholder sentence for a project description.</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="tilt" href="https://open.spotify.com/playlist/2ewHpbvHJnC5y8hv1EPQEX?si=a8ba8cc01c6341c1">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card rose-background">
                                    <div className="card-title">
                                        <p className="project-title contrast">BREAK!</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="breakCard" src="break.png"></img>
                                    <div className="card-text">
                                        <a>Disrupt your rhythm.</a>
                                    </div>
                                </div>
                            </div>
                            <div className="tilt" href="https://open.spotify.com/playlist/5g1JfgFSwuaie2Y5KrxuPC?si=8343ac23a4c84e07">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card rose-background">
                                    <div className="card-title">
                                        <p className="project-title">Honey Lavender</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="honeyCard" src="honeyLavender.png"></img>
                                    <div className="card-text">
                                        <a>What follows the golden hour.</a>
                                    </div>
                                </div>
                            </div>
                            <div className="tilt" href="https://open.spotify.com/playlist/4hc3AGvdacuxfpti9eGuyx?si=b3c74e5feafb4cc9">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card rose-background">
                                    <div className="card-title">
                                        <p className="project-title">Jacuzzi Joints</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="jacuzziCard" src="jacuzziJoints.png"></img>
                                    <div className="card-text">
                                        {/* <a>This is a placeholder sentence for a project description.</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="tilt" href="https://open.spotify.com/playlist/0VsPScifQ8R4S0f6C4YBDg?si=a0052dd06c8a4aaf">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card rose-background">
                                    <div className="card-title">
                                        <p className="project-title">Neon Nights</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="neonCard" src="neonNights.png"></img>
                                    <div className="card-text">
                                        {/* <a>This is a placeholder sentence for a project description.</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="tilt" href="https://open.spotify.com/playlist/5hWoaVsFcfCqeH5EBcnVcK?si=cc872ea0bbec47db">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card rose-background">
                                    <div className="card-title">
                                        <p className="project-title">Lunar</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="lunarCard" src="lunar.png"></img>
                                    <div className="card-text">
                                        <a>To the moon and back.</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <img className="pageLight" id="playlistLight" src="playlistLight.png"></img> */}
                        <div className="lightbar pink rightbar"></div>
                    </div>
                </div>   
            </div>
        )
    }
}

export default Playlist
