import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './nike.css';
import {SRLWrapper} from "simple-react-lightbox";
import red1 from './nike/red1.jpg';
import red2 from './nike/red2.jpg';
import orange from './nike/orange.jpg';
import yellow from './nike/yellow.jpg';
import green from './nike/green.jpg';
import blue from './nike/blue.jpg';
import purple from './nike/purple.jpg';
import black from './nike/blackandwhite.jpg';



export class Nike extends Component {
    render() {
        return (
                <div className="gallery">
                    <SRLWrapper>
                        <img id="red1" src={red1}></img>
                        <img id="red2" src={red2}></img>
                        <img id="orange" src={orange}></img>
                        <img id="yellow" src={yellow}></img>
                        <img id="green" src={green}></img>
                        <img id="black" src={black}></img>
                        <img id="blue" src={blue}></img>
                        <img id="purple" src={purple}></img>   
                    </SRLWrapper>
                </div>
        )
    }
}

export default Nike