import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './musicInbox/Montserrat/Montserrat-Bold.ttf';
import './musicInbox/Montserrat/Montserrat-Regular.ttf';
import './musicInbox.css';
import './lighten.css';
import './havit.css';

import home from './havit/havitHome.png';
import homeColors from './havit/havitHomeColors.png';
import skills from './havit/havitSkills.png';
import social from './havit/havitSocial.png';
import inspo from './havit/spiritInspo.png';
import form from './havit/spiritForm.png';
import intro from './havit/spiritIntro.png';
import allSkills from './havit/spiritSkills.png';
import tasks from './havit/spiritTasks.png';
import completeTask from './havit/completeTask.gif';
import doubleDiamond from './havit/doubleDiamond.png';

export class Havit extends Component {

    render() {


        return (
                <div className="havitPage">
                    <div className="title">
                        <h2>Spirit</h2>
                    </div>
                    <br></br>
                    <a className="sec centered">
                    Spirit is a concept for a gamified habit and skill tracking app meant to encourage personal growth. 
                    </a>
                    <div className='sec'> 
                        <div className='sec-split'> 
                            <img className="img-inbox" id="havitHome" src={intro}></img>
                            <br></br>
                            <br></br>
                            <div>
                                <p>
                                    <b>Overview</b>
                                </p>
                                <br></br>
                                <br></br>
                                <a>
                                    A couple of my friends and I have a group chat we use to keep each other accountable for expressing and achieving our goals. We usually send in pictures of ourselves at the gym or updates on our progress and it has worked well enough when we had singular goals like getting consistent exercise and eating healthy. When everyone had many different goals and skills they wanted to improve on, the group chat quickly became disorganized. This idea of progressing goals and skills while sharing achievements with friends was the inspiration for Spirit. I wanted to help my friends and I feel motivated by seeing daily progress for building habits and earning rewards for completing our missions.                           
                                </a>
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    
                    <div className="sec"> 
                        <img className="img-inbox" id="havitHomeScreenColors" src={homeColors}></img>
                        <br></br>
                        <br></br>
                        <a>
                            I took inspiration from video game designs to turn the process of habit building into an exciting journey. Every user’s personal habit avatar would grow along with them, gaining experience when they complete their daily habits and missions or by developing their own skills.                         </a>
                        <br></br>
                        <img className="img-inbox" id="spiritTasks" src={tasks}></img>
                        <br></br>
                        <br></br>



                    </div>

                    <div className='sec'>
                        <p>
                            <b>Design Process</b>
                        </p>
                        <br></br>
                        <br></br>
                        <a>
                            My design process typically follows the “Double Diamond” framework, consisting of two phases: research and ideation. Both of these phases involve gathering problems or solutions, then narrowing down to the most essential ones. I consider a design successful if it can achieve synergy with both the usability and the story.                         
                        </a>
                        <br></br>
                        <br></br>
                        <img className="img-inbox" id="double-diamond" src={doubleDiamond}></img>
                        
                    </div>
                   
                    <div className='sec'>
                        <p>
                            <b>User Research</b>
                        </p>
                        <br></br>
                        <br></br>
                        <a>
                            The first directive was defining the needs of my target users. In this case, my friends in the “accountability” group chat were who I was defining this for. Typical interactions in this group chat included setting monthly or yearly goals and sending updates in the form of pictures. Generalizing from this audience, anyone who is seeking to build new habits or skills should be welcomed by the app.                         
                        </a>
                        <br></br>
                        <br></br>
                        <a>
                            After conducting interviews and researching which factors led to the development of strong habits, I narrowed the responses to some key traits:
                        </a>
                        <br></br>
                        <br></br>
                        <dl className='traits-list'>
                            <div className='trait'>
                                <dt><b>Setting clear, manageable goals</b></dt>
                                <br></br>
                                <br></br>
                                <dd>This is ultimately the responsibility of the user, but creating a space where they can write down their goals in terms of actionable tasks is the first step towards building habits.</dd>
                                <br></br>
                                <br></br>
                            </div>
                            <div className='trait'>
                                <dt><b>Promoting consistency</b></dt>
                                <br></br>
                                <br></br>
                                <dd>Many sources report that doing some task everyday for 21 days makes that task a habit and doing it for 66-90 days makes it automatic or part of a lifestyle. Therefore, to encourage building habits or changing a lifestyle, the app must promote consistency</dd>
                                <br></br>
                                <br></br>
                            </div>
                            <div className='trait'>
                                <dt><b>Establishing rewards</b></dt>
                                <br></br>
                                <br></br>
                                <dd>All habits are driven by some satisfying reward in response to fulfilling a craving. An unsatisfying response won’t motivate the building of a new habit and, over time, can also eliminate cravings for an existing habit. </dd>
                                <br></br>
                                <br></br>
                            </div>
                            <div className='trait'>
                                <dt><b>Staying accountable</b></dt>
                                <br></br>
                                <br></br>
                                <dd>Having an accountability partner can help people stay motivated and committed to their goals. Fundamentally, this can be similar to a type of reward when a partner praises someone for sticking to their habits or motivation when they start to lose focus.</dd>
                                <br></br>
                                <br></br>
                            </div>
                            <div className='trait'>
                                <dt><b>Organizing multiple skills</b></dt>
                                <br></br>
                                <br></br>
                                <dd>For people who are trying to develop multiple skills, organizing the activities and progress into different categories will be important.</dd>
                                <br></br>
                                <br></br>
                            </div>
                        </dl>
                        <br></br>
                        <br></br>
                    </div>

                    <div className='sec'>
                        <div className='sec-column-left'>
                            <p>
                                <b>Discovering the Theme</b>
                            </p>
                            <br></br>
                            <br></br>
                            <a>
                                The second phase consisted of creating a story and theme that made the app compelling to use. User research and testing aim to establish clear usability for the product. This is meant to show that a new user is able to navigate the app and accomplish the tasks they hoped to complete, however, the story and feel is what makes the user actually want to use the product.
                            </a>
                            <br></br>
                            <br></br>
                        </div>
                        <div className="sec-split"> 
                            <a>
                                Using Pinterest, I sought out inspiration for how I wanted the aesthetics of the product to look and feel. I wanted to give the users of Spirit a feeling that they were seeing a reflection of their soul. The mixture of ethereal, soft gradients with the sleek, futuristic elements best represented my idea of “digital spirit”.
                            </a>
                            <br></br>
                            <br></br>
                            <img className="img-inbox" id="spiritInspo" src={inspo}></img>
                            <br></br>
                            <br></br>
                        </div>
                    </div>

                    <div className='sec'>
                        <p>
                            <b>Building the Solution</b>
                        </p>
                        <br></br>
                        <br></br>
                        <a>
                            A necessary trait for building habits is consistency. To promote the repeated completion of habit tasks, I implemented a streak counter which scaled the rewards as the counter increased. This feature was designed to be positive reinforcement for the user, which would keep them excited for reaching new milestones in their consistency. In fact, the positive reinforcement is the fundamental difference between Spirit and other gamified habit building apps. These other applications use a health bar system that decreases in health if a task is not completed. This type of punishment subtly affects the user’s psychology making them feel little bits of shame or guilt which they would begin to associate with the use of the app. Ultimately, those feelings will reduce user retention so Spirit aims to kindle excitement towards the habit-building process. 
                        </a>
                        <br></br>
                        <br></br>
                        <img className="img-inbox" id="spiritHome" src={home}></img>
                        <br></br>
                        <br></br>
                        <div className='sec-split'>
                            <a>
                                Furthermore, the action of completing a task is animated to excite the user as well. By having the user press and hold an item to complete it, the app creates a slight bit of tension or suspense while showing the action animation. This tension would be resolved with a satisfying completion to match the actual feeling of doing some activity on the to-do list. With the use of haptic feedback and sound design along with the animation, I wanted to create a satisfying sensation for multiple senses. This would make completing a task feel more rewarding and permanent than simply checking and unchecking a checkbox. 
                            </a>
                            <br></br>
                            <br></br>
                            <img className="img-inbox" id="completedTask" src={completeTask}></img>
                            <br></br>
                            <br></br>
                        </div>
                        <a>
                            Finally, the social activity was designed with the intention of not being addicting. Posts that have already been seen will collapse so it is visually clear when to stop scrolling. The focus of the app is self-development and accountability over validation.
                        </a>
                        <br></br>
                        <br></br>
                        <img className="img-inbox" id="havitSocial" src={social}></img>
                        <br></br>
                        <br></br>
                    </div>

                    <div className='sec'>
                        <p>
                            <b>Results and Takeaways</b>
                        </p>
                        <br></br>
                        <br></br>
                        <a>
                            After presenting a prototype to several people, I was glad to see that they were able to navigate between pages and successfully create and interact with the habit tasks. However, many people were confused about what to do with the skills page. It wasn’t clear to the users how their skills would connect to the other pages and what they could do while interacting with the individual skills. A common response was that there seemed to be too much information presented when viewing a skill and its activity.
                        </a>
                        <br></br>
                        <br></br>
                        <a>
                            For the next iteration of user testing, the simplified version of the skill activities was better received. The users were able to understand that they were viewing their recent activity related to each of their skills. It seemed that some of the confusion stemmed from the user immediately being presented with a page full of skills and completed activities, whereas a new user would start with a blank page and gain familiarity as they slowly create new skills and add activities. 
                        </a>
                        <br></br>
                        <br></br>
                        <img className="img-inbox" id="havitSkills" src={skills}></img>
                        <br></br>
                        <br></br>
                        <div className='sec-split'>
                            <img className="img-inbox" id="spiritForm" src={form}></img>
                            <br></br>
                            <br></br>
                            <a>
                                Furthermore, asking the users to try and create a habit, mission, or challenge with the prototype, showed to give them a better understanding of how the skills factor into the experience. Specifically, the section in the form that allowed the user to list the skills related to the task seemed to be helpful. 
                            </a>
                            <br></br>
                            <br></br>
                        </div>


                    </div>

                    <img className="img-inbox" id="spiritSkills" src={allSkills}></img>
                        <br></br>
                        <br></br>

                    <div className='sec'>
                        <p>
                            <b>Conclusion</b>
                        </p>
                        <br></br>
                        <br></br>
                        <a>
                            This entire process has been an exciting and incredible learning experience for me. I find a lot of enjoyment in creating experiences and actually building this project would directly impact the lives of the people in my community. 
                        </a>
                        <br></br>
                        <br></br>
                        <a>
                            I think that there were several challenges that came with dealing with a problem that I was personally invested in, however. Primarily, I found myself assuming what I thought other users would want in the app prior to doing the actual research. This caused me to have to restart my design process to set the concerns of the intended users as the central voice instead of my own. I had to really question what I believed in to verify whether it was able to be supported by research or something I had assumed to know. For future projects, I want to do a better job of organizing this research and build better clarity prior to starting the design process.
                        </a>
                        <br></br>
                        <br></br>
                    </div>
                </div>
        )
    }
}

export default Havit