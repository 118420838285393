import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './homeandcity.css';
import {SRLWrapper} from "simple-react-lightbox";
import home from './home and city/time in a tree.jpg';
import city from './home and city/city.jpg';


export class Homeandcity extends Component {
    render() {
        return (
                <div className="gallery">
                    <SRLWrapper>
                        <img id="home" src={home} alt="Time in a Tree"></img>
                        <img id="city" src={city} alt="Neon Nights"></img>
                    </SRLWrapper>
                </div>
        )
    }
}

export default Homeandcity