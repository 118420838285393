import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './treehouse.css';
import {SRLWrapper} from "simple-react-lightbox";
import treehousepic from './treehouse/treehouse.jpg';
import star from './treehouse/star.jpg';
import light from './treehouse/light.jpg';


export class Treehouse extends Component {
    render() {
        return (
                <div className="gallery treehouse">
                    <SRLWrapper>
                        <img id="treehousepic" src={treehousepic} alt="Treehouse"></img>
                        <img id="star" src={star} alt="North Star"></img>
                        <img id="light" src={light} alt="Chandelier"></img>
                    </SRLWrapper>
                </div>
        )
    }
}

export default Treehouse