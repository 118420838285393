import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './misc.css';
import {SRLWrapper} from "simple-react-lightbox";
import wanderer from './misc/wanderer.png';
import seek from './misc/seek.png';
import seeking from './misc/seeking.png';
import silentPrincess from './misc/silent princess.png';
import staticSnow from './misc/static snow.png';
import telescoped from './misc/telescoped.png';



export class Misc extends Component {
    render() {
        return (
                <div className="gallery">
                    <SRLWrapper>
                        <img id="wanderer" src={wanderer} alt="Big Steppa"></img>
                        <img id="seek" src={seek} alt="Seeker"></img>
                        <img id="seeking" src={seeking} alt="Seeking"></img>
                        <img id="silentPrincess" src={silentPrincess} alt="Silent Princess"></img>
                        <img id="staticSnow" src={staticSnow} alt="Static Snow"></img>
                        <img id="telescoped" src={telescoped} alt="Star Crossed"></img>
                    </SRLWrapper>
                </div>
        )
    }
}

export default Misc