import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './photo.css';
import hi23 from './photo/hawaii/hi23.jpg';
import purple from './photo/nike/purple.jpg';
import mch1 from './photo/music/mch1.jpg';
import duck2 from './photo/wildlife/duck2.jpg';
import bs22 from './photo/borrego/bs22.jpg';
import swov1 from './photo/swoveralls/DSC03113.jpg';


export class Photo extends Component {
    render() {
        return (
            <div className="home">
                <div className="section design">
                    <div className="section title-text">
                        <img className="pageTitle" id="photoTitle" src="photoTitle.png"></img>
                        {/* <a>These are the photo albums.</a> */}
                    </div>

                
                    <div className="section flex lightbox">
                        {/* <img className="pageLight" id="photoLight" src="photoLight.png"></img> */}
                        <div className="lightbar photo leftbar"></div>
                        <div className="section flex cards">
                            <Link to='/photo/concerts'>
                                <div className="tilt">
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <div className="card light-background">
                                        <div className="card-title">
                                            <p className="project-title">Concerts</p>
                                            {/* <p>2022</p> */}
                                        </div>
                                        <img className="card-image" id="concertsCard" src={mch1}></img>
                                        {/* <div className="card-text">
                                            <a>This is a placeholder sentence for a project description.</a>
                                        </div> */}
                                    </div>
                                </div>
                            </Link>
                            <Link to='/photo/wildlife'>
                                <div className="tilt">
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <div className="card light-background">
                                        <div className="card-title">
                                            <p className="project-title">Wildlife</p>
                                            {/* <p>2022</p> */}
                                        </div>
                                        <img className="card-image" id="wildlifeCard" src={duck2}></img>
                                        {/* <div className="card-text">
                                            <a>This is a placeholder sentence for a project description.</a>
                                        </div> */}
                                    </div>
                                </div>
                            </Link>
                            <Link to='/photo/swoosh'>
                                <div className="tilt">
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <div className="card light-background">
                                        <div className="card-title">
                                            <p className="project-title contrast">Swoo's and Hues</p>
                                            {/* <p>2022</p> */}
                                        </div>
                                        <img className="card-image" id="nikeCard" src={purple}></img>
                                        {/* <div className="card-text">
                                            <a>This is a placeholder sentence for a project description.</a>
                                        </div> */}
                                    </div>
                                </div>
                            </Link>
                            <Link to='/photo/hawaii'>
                                <div className="tilt">
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <div className="card light-background">
                                        <div className="card-title">
                                            <p className="project-title">Hawaii</p>
                                            {/* <p>2022</p> */}
                                        </div>
                                        <img className="card-image" id="hawaiiCard" src={hi23}></img>
                                        {/* <div className="card-text">
                                            <a>This is a placeholder sentence for a project description.</a>
                                        </div> */}
                                    </div>
                                </div>
                            </Link>
                            <Link to='/photo/borrego'>
                                <div className="tilt">
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <div className="card light-background">
                                        <div className="card-title">
                                            <p className="project-title contrast">Borrego Springs</p>
                                            {/* <p>2022</p> */}
                                        </div>
                                        <img className="card-image" id="borregoCard" src={bs22}></img>
                                        {/* <div className="card-text">
                                            <a>This is a placeholder sentence for a project description.</a>
                                        </div> */}
                                    </div>
                                </div>
                            </Link>
                            <Link to='/photo/swoveralls'>
                                <div className="tilt">
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <span className="t_over"></span>
                                    <div className="card light-background">
                                        <div className="card-title">
                                            <p className="project-title contrast">Swoveralls</p>
                                            {/* <p>2022</p> */}
                                        </div>
                                        <img className="card-image" id="swoverallCard" src={swov1}></img>
                                        {/* <div className="card-text">
                                            <a>This is a placeholder sentence for a project description.</a>
                                        </div> */}
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <img className="pageLight" id="photoLight" src="photoLight.png"></img> */}
                        <div className="lightbar photo rightbar"></div>
                    </div>
                </div>   
            </div>

                // <div>
                //     <Link to='/photo/music'>
                //         <img id="musicSD" src="musicSD.png"></img>
                //     </Link>
                //     <Link to='/photo/nike'>
                //         <img id="nikeSD" src="nikeSD.png"></img>
                //     </Link>
                //     <Link to='/photo/wildlife'>
                //         <img id="wildlifeSD" src="wildlifeSD.png"></img>
                //     </Link>
                // </div>
        )
    }
}

export default Photo
