import React, {Component} from "react";
import './header.css';
import { Link } from 'react-router-dom'


export class Header extends Component {  

    render(){
        return(
            <div className="header">
                <div className="navLogo">
                    <Link to='/'>
                        <img id="svLogo" src="sv.png"></img>
                    </Link>
                </div>
                <div className="navLogo">
                    <ul className="nav">
                        <li className="nav-item">
                            <img className="icon" id="designIcon" src="designIcon.png" alt="Design"></img>
                            <ul className="subNav">
                                <li className="subNav-item">
                                    <Link to='/projects'>
                                        <a>Design Projects</a>
                                    </Link>
                                </li>
                                <li className="subNav-item">
                                    <Link to='/art'>
                                        <a>Art</a>
                                    </Link>
                                </li>
                                <li className="subNav-item">
                                    <Link to='/fashion'>
                                        <a>Fashion</a>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <img className="icon" id="photoIcon" src="photoIcon.png" alt="Photo"></img>
                            <ul className="subNav">
                                <li className="subNav-item">
                                    <Link to='/photo'>
                                        <a>Photo</a>
                                    </Link>
                                </li>
                                {/* <li className="subNav-item">
                                    <Link to='/video'>
                                        <a>Video</a>
                                    </Link>
                                </li> */}
                            </ul>
                        </li>
                        <li className="nav-item">
                            <img className="icon" id="musicIcon" src="musicIcon.png" alt="Music"></img>
                            <ul className="subNav">
                                {/* <li className="subNav-item">
                                    <Link to='/music'>
                                        <a>Music</a>
                                    </Link>
                                </li> */}
                                <li className="subNav-item">
                                    <Link to='/playlists'>
                                        <a>Playlists</a>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <img className="icon" id="writingIcon" src="writingIcon.png" alt="Writing"></img>
                            <ul className="subNav">
                                <li className="subNav-item">
                                    <Link to='/stories'>
                                        <a>Stories</a>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="menu">
                            <img className="icon" id="menuIcon" src="menuIcon.png" alt="Menu" onClick={(e) => {
                                document.getElementsByClassName('mobile-menu')[0].style.display = 'flex';
                                document.getElementsByClassName('menu')[0].style.background = '#242424c2';
                                document.getElementById('menuIcon').style.display = 'none';
                                document.getElementById('closeIcon').style.display = 'block';
                            }}></img>
                            <img className="icon" id="closeIcon" src="closeIcon.png" alt="Close" onClick={(e) => {
                                document.getElementsByClassName('mobile-menu')[0].style.display = 'none';
                                document.getElementsByClassName('menu')[0].style.background = 'none';
                                document.getElementById('menuIcon').style.display = 'block';
                                document.getElementById('closeIcon').style.display = 'none';
                            }}></img>
                            <ul className="mobile-menu">
                                <li className="mobile-nav-item">
                                    <img className="mobile-icon" id="designIcon" src="designIcon.png" alt="Design"></img>
                                    <ul className="mobile-subNav">
                                        <li className="mobile-subNav-item">
                                            <Link to='/projects'>
                                                <a onClick={(e) => {
                                document.getElementsByClassName('mobile-menu')[0].style.display = 'none';
                                document.getElementsByClassName('menu')[0].style.background = 'none';
                                document.getElementById('menuIcon').style.display = 'block';
                                document.getElementById('closeIcon').style.display = 'none';}}>Design Projects</a>
                                            </Link>
                                        </li>
                                        <li className="mobile-subNav-item">
                                            <Link to='/art'>
                                                <a onClick={(e) => {
                                document.getElementsByClassName('mobile-menu')[0].style.display = 'none';
                                document.getElementsByClassName('menu')[0].style.background = 'none';
                                document.getElementById('menuIcon').style.display = 'block';
                                document.getElementById('closeIcon').style.display = 'none';}}>Art</a>
                                            </Link>
                                        </li>
                                        <li className="mobile-subNav-item">
                                            <Link to='/fashion'>
                                                <a onClick={(e) => {
                                document.getElementsByClassName('mobile-menu')[0].style.display = 'none';
                                document.getElementsByClassName('menu')[0].style.background = 'none';
                                document.getElementById('menuIcon').style.display = 'block';
                                document.getElementById('closeIcon').style.display = 'none';}}>Fashion</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="mobile-nav-item">
                                    <img className="mobile-icon" id="photoIcon" src="photoIcon.png" alt="Photo"></img>
                                    <ul className="mobile-subNav">
                                        <li className="mobile-subNav-item">
                                            <Link to='/photo'>
                                                <a onClick={(e) => {
                                document.getElementsByClassName('mobile-menu')[0].style.display = 'none';
                                document.getElementsByClassName('menu')[0].style.background = 'none';
                                document.getElementById('menuIcon').style.display = 'block';
                                document.getElementById('closeIcon').style.display = 'none';}}>Photo</a>
                                            </Link>
                                        </li>
                                        {/* <li className="subNav-item">
                                            <Link to='/video'>
                                                <a onClick={(e) => {
                                document.getElementsByClassName('mobile-menu')[0].style.display = 'none';
                                document.getElementsByClassName('menu')[0].style.background = 'none';
                                document.getElementById('menuIcon').style.display = 'block';
                                document.getElementById('closeIcon').style.display = 'none';}}>Video</a>
                                            </Link>
                                        </li> */}
                                    </ul>
                                </li>
                                <li className="mobile-nav-item">
                                    <img className="mobile-icon" id="musicIcon" src="musicIcon.png" alt="Music"></img>
                                    <ul className="mobile-subNav">
                                        {/* <li className="subNav-item">
                                            <Link to='/music'>
                                                <a onClick={(e) => {
                                document.getElementsByClassName('mobile-menu')[0].style.display = 'none';
                                document.getElementsByClassName('menu')[0].style.background = 'none';
                                document.getElementById('menuIcon').style.display = 'block';
                                document.getElementById('closeIcon').style.display = 'none';}}>Music</a>
                                            </Link>
                                        </li> */}
                                        <li className="mobile-subNav-item">
                                            <Link to='/playlists'>
                                                <a onClick={(e) => {
                                document.getElementsByClassName('mobile-menu')[0].style.display = 'none';
                                document.getElementsByClassName('menu')[0].style.background = 'none';
                                document.getElementById('menuIcon').style.display = 'block';
                                document.getElementById('closeIcon').style.display = 'none';}}>Playlists</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="mobile-nav-item">
                                    <img className="mobile-icon" id="writingIcon" src="writingIcon.png" alt="Writing"></img>
                                    <ul className="mobile-subNav">
                                        <li className="mobile-subNav-item">
                                            <Link to='/stories'>
                                                <a onClick={(e) => {
                                document.getElementsByClassName('mobile-menu')[0].style.display = 'none';
                                document.getElementsByClassName('menu')[0].style.background = 'none';
                                document.getElementById('menuIcon').style.display = 'block';
                                document.getElementById('closeIcon').style.display = 'none';}}>Stories</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Header;