import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './stories.css';


export class Stories extends Component {
    render() {
        return (
            <div className="home">
                <div className="section design">
                    <div className="section title-text">
                        <img className="pageTitle" id="storyTitle" src="storyTitle.png"></img>
                        <a>Coming Soon!</a>
                    </div>

                
                    <div className="section flex lightbox">
                        <img className="pageLight" id="storyLight" src="storyLight.png"></img>
                        <div className="section flex cards">
                            {/* <div className="tilt">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card lime-background">
                                    <div className="card-title">
                                        <p>Project Name</p>
                                        <p>2022</p>
                                    </div>
                                    <img className="card-image" id="testCard" src="noEgo.png"></img>
                                    <div className="card-text">
                                        <a>This is a placeholder sentence for a project description.</a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <img className="pageLight" id="storyLight" src="storyLight.png"></img>
                    </div>
                </div>   
            </div>
        )
    }
}

export default Stories
