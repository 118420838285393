import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './wildlife.css';
import {SRLWrapper} from "simple-react-lightbox";
import bird1 from './wildlife/bird1.jpg';
import bird2 from './wildlife/bird2.jpg';
import bird3 from './wildlife/bird3.jpg';
import cat1 from './wildlife/cat1.jpg';
import cat2 from './wildlife/cat2.jpg';
import coyote from './wildlife/coyote.jpg';
import deer1 from './wildlife/deer1.jpg';
import deer2 from './wildlife/deer2.jpg';
import deer3 from './wildlife/deer3.jpg';
import duck1 from './wildlife/duck1.jpg';
import duck2 from './wildlife/duck2.jpg';
import duck3 from './wildlife/duck3.jpg';
import duck4 from './wildlife/duck4.jpg';
import fish from './wildlife/fish.jpg';
import fox from './wildlife/fox.jpg';
import gecko1 from './wildlife/gecko1.jpg';
import gull from './wildlife/gull.jpg';
import lizard2 from './wildlife/lizard2.jpg';
import turtle from './wildlife/turtle.jpg';


export class Wildlife extends Component {
    render() {
        return (
                <div className="gallery">
                    <SRLWrapper>
                        <img id="duck1" src={duck1}></img>
                        <img id="cat1" src={cat1}></img>
                        <img id="cat2" src={cat2}></img>
                        <img id="duck3" src={duck3}></img>
                        <img id="duck2" src={duck2}></img>
                        <img id="duck4" src={duck4}></img>
                        <img id="turtle" src={turtle}></img>
                        <img id="lizard2" src={lizard2}></img>
                        <img id="gecko1" src={gecko1}></img>
                        <img id="gull" src={gull}></img>
                        <img id="fish" src={fish}></img> 
                        <img id="fox" src={fox}></img>
                        <img id="bird1" src={bird1}></img>    
                        <img id="bird2" src={bird2}></img> 
                        <img id="coyote" src={coyote}></img> 
                        <img id="bird3" src={bird3}></img> 
                        <img id="deer1" src={deer1}></img> 
                        <img id="deer2" src={deer2}></img> 
                        <img id="deer3" src={deer3}></img> 
                    </SRLWrapper>
                </div>
        )
    }
}

export default Wildlife