import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './musicInbox/Montserrat/Montserrat-Bold.ttf';
import './musicInbox/Montserrat/Montserrat-Regular.ttf';
import './musicInbox.css';
import './lighten.css';
import './swov-pins.css';

import blastoff from './thinkingCap/Blastoff-mockup.png';

export class SwovPins extends Component {

    render() {


        return (
                <div className="swovPage">

                   
                   
                </div>
        )
    }
}

export default SwovPins