import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './musicInbox/Montserrat/Montserrat-Bold.ttf';
import './musicInbox/Montserrat/Montserrat-Regular.ttf';
import './musicInbox.css';
import './lighten.css';
import './swov-cart.css';


import cartEdit from './swoveralls/cartEdit.png';
import cartMockup from './swoveralls/cartMockup.png'; 

export class SwovCart extends Component {

    render() {


        return (
                <div className="swovPage">
                    <div className="title">
                        <h2>Swoveralls Smart Cart Redesign</h2>
                        <br></br>
                    </div>
                    <div className="subtitle">
                        <h3>Overview</h3>
                        <br></br>
                        
                    </div>
                    <div className='introd'>
                        <a>This project aimed to redesign the shopping cart pop-up on mobile devices. By urging my team to regularly use our website from the perspective of a customer, we discovered that the cart served as a major pain point in the customer journey. </a> 
                        <br></br>
                        <br></br>
                        <img className="cart-image" id="cartEdit" src={cartEdit} ></img>
                        <br></br>
                        <br></br>
                        <a>Specifically, on mobile devices, there was too much clutter with many elements taking up more space than necessary. Namely, our Gift with Purchase (GWP) section was not able to be minimized and covered up to 30% of the screen when a gift was unlocked. This left a very small window for the customer to actually view what items they had in their cart and made it difficult for them to see any recommended items to add to their cart.</a>
                        <br></br>
                        <br></br>
                    </div>
                    <div className="subtitle">
                        <h3>Process</h3>
                        <br></br>
                        
                    </div>
                    <div className='body'>
                        <a>My objective was to reduce this clutter and let the list of items in the cart become the dominant section as it should be. </a>
                        <br></br>
                        <br></br>
                        <a>The GWP and the footer section with the subtotal and checkout button were the sections that competed with the cart for space. </a>
                        <br></br>
                        <br></br>
                        <img></img>
                        <div className='sec-split'>
                            <a>For the GWP section, overlaying the possible gifts on top of the progress bar and reducing the margins around the prompts to select the size or style of the individual gifts contributed the most to reducing space. </a>
                            <img className="cart-image" src={cartMockup}></img>
                        </div>
                        <br></br>
                        <br></br>
                        <a>By systematically removing any redundant information, overly large spacing, and minimizing lines of information, I was able to increase the area of the cart by around 25%. </a>
                    </div>
                    <div className="subtitle">
                        <br></br>
                        <br></br>
                        <h3>Testing</h3>
                        <br></br>
                        
                    </div>
                    <div className='body'>
                        <a>We tested the efficacy of this cart by A/B testing with an even split. The test randomly determined which customer would view the new cart and measured conversion as someone who continued to checkout. </a>
                        <br></br>
                        <br></br>
                        <a>After 2 weeks of testing to reach statistical significance, we saw there was a 17% higher conversion rate on the newly designed cart. We expected this increase in conversion but were surprised by the amount in which conversion increase as well as an even more unexpected increase in Average Order Value (AOV) by 4%.</a>
                    </div>
                    <div className="subtitle">
                        <br></br>
                        <br></br>
                        <h3>Conclusion</h3>
                        <br></br>

                    </div>
                    <div className='conc'>
                        <a>I believe that giving the cart more space allowed more customers to view the recommended or suggested products also displayed in the cart. With more visibility, there was likely more conversion that increased the size of the orders. </a>
                        <br></br>
                        <br></br>
                        <a>This redesign was considered a success for my team and encouraged us to continue engaging in different aspects of the customer journey to discover potential pain points. </a>
                    </div>
                   
                   
                </div>
        )
    }
}

export default SwovCart