import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './memory.css';
import {SRLWrapper} from "simple-react-lightbox";
import butterfly from './memory/butterflyEffect.jpg';
import ego from './memory/ego death.jpg';


export class Memory extends Component {
    render() {
        return (
                <div className="gallery">
                    <SRLWrapper>
                        <img id="butterfly" src={butterfly} alt="Butterfly Effect"></img>
                        <img id="ego" src={ego} alt="Ego Death"></img>
                    </SRLWrapper>
                </div>
        )
    }
}

export default Memory