import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './musicInbox/Montserrat/Montserrat-Bold.ttf';
import './musicInbox/Montserrat/Montserrat-Regular.ttf';
import './musicInbox.css';
import './lighten.css';

import overview from './lighten/lightenOverview.png';
import logo from './lighten/lighten.jpg';
import annote from './lighten/annote.png';
import branding from './lighten/lightenBranding.png';
import home from './lighten/lighten1.png';
import collections from './lighten/lighten2.png';
import collectionPage from './lighten/lightenCollectionViews.png';
import discussions from './lighten/lightenDiscussion.png';
import browserExpanded from './lighten/lightenBrowser.png';

export class Lighten extends Component {

    render() {


        return (
                <div className="lightenPage">
                    <div className="title">
                        <h2>Lighten</h2>
                    </div>
                    <br></br>
                    <a className="sec">
                        This is the branding and design concept for Lighten—a tool that functions as a digital highlighter allowing you to annotate and discuss the content on any public webpage. 
                    </a>
                    <br></br>
                    <br></br>
                    <img className="img-inbox" id="homeScreen" src={home}></img>
                    <br></br>
                    <br></br>
                    <div className="sec"> 
                        <p>
                            <b>Overview</b>
                        </p>
                        <br></br>
                        <a>
                        Earlier this year, my brother excitedly ran up to me asking, “I have an idea, do you want to hear it?” 
                        <br></br>
                        <br></br>
                        If this was anything like his last idea to put soy sauce and mayo on pasta, I would be intrigued nonetheless. 
                        So, I replied, “Yeah sure, what’s your idea?”
                        <br></br>
                        <br></br>
                        He had been reading medical research papers and came to the conclusion that a lot of these papers were written to be more complicated than they needed to be. The discoveries would be impressive if the general public could only understand what they were.
                        <br></br>
                        <br></br>
                        “I want to make a tool that allows people to highlight, take notes, and have discussions on any public webpage.” 
                        <br></br>
                        <br></br>
                        All the world’s information is supposedly at our fingertips but it isn’t made accessible due to complexity or being buried in misinformation. The spread of fake news has especially become a major problem in recent years. 
                        <br></br>
                        <br></br>
                        “If we could discuss about any news straight from the source, we are bound to uncover the truth.” 
                        <br></br>
                        <br></br>
                        It seems that relying on technology to fact check every bit of information won’t be possible for a long time, so relying on the collective knowledge of the people on the internet may be the best bet.
                        </a>
                        <br></br>
                        <br></br>
                        <img className="img-inbox" id="overview" src={overview}></img>
                        <br></br>
                        <br></br>
                    </div>
                    <div className="sec"> 
                        <p>
                            <b>My Role</b>
                        </p>
                        <br></br>
                        <a>
                        I saw the huge potential in my brother’s idea, so I sought out to crystallize his vision by constructing the brand identity and the design of the product. 
                        </a>
                        <br></br>

                        
                        <br></br>
                    </div>
                    <div className="sec"> 
                        <p>
                            <b>The Branding</b>
                        </p>
                        <br></br>
                        <a>
                        The main purpose of this tool is to empower the truth and the people who search for it. We visualized the tool as a highlighter—the analog tool for annotating—and sought to reimagine what the digital version would look like. 

The stereotypical highlighter we imagined was the bright yellow pen but then, we realized that the default text highlighting on most computers was light blue. I wanted to build from there, using the blue color that most people would recognize as digital highlighting. The name came to us shortly after; instead of highlighting something we wanted to “Lighten it”.  Similar to “enlighten”, Lighten seemed to fit for the tool that was meant to empower the truth.
                        </a>
                        <br></br>
                        <img className="img-inbox" id="branding" src={branding}></img>
                        <br></br>
                    </div>
                    <div className="sec"> 
                        <p>
                            <b>The Design</b>
                        </p>
                        <br></br>
                        <br></br>
                        <div className="sec-split"> 
                            <div>
                                <a>
                                    <b>Logo Design:</b>
                                </a>
                                <br></br>
                                <br></br>
                                <a>
                                    I designed the logo with several images in mind. The overall shape was made to look like the letter ‘L’, while the image resembles a highlighter drawing lines as well as a prism diffusing light from one beam to three. This represents the purpose of separating the truth and misinformation from the Internet. 
                                </a>
                            </div>
                            
                            <img className="img-inbox" id="logo" src={logo}></img>
                        </div>

                        <br></br>
                        <br></br>

                        <a>
                            <b>UX Design:</b>
                        </a>
                        <br></br>
                        <a>
                        The use of light was a central theme in the design. I made the product solely with a dark theme to bring more focus on the elements that used light. 

The highlighted annotations, which we called Annotes, were the main components that carried information, so I designed the product around organizing, discussing, and sharing these Annotes. 

Just like physical highlighters come in many different colors, we wanted Lighten to give the user lots of color options to label their collections and set a new default color scheme.
                        </a>
                        <br></br>
                        <img className="img-inbox" id="browser" src={browserExpanded}></img>
                        <img className="img-inbox" id="annote" src={annote}></img>
                        
                        
                        <br></br>

                        <img className="img-inbox" id="collections" src={collections}></img>
                        <br></br>
                        <img className="img-inbox" id="collectionPage" src={collectionPage}></img>
                        <br></br>
                        <img className="img-inbox" id="discussions" src={discussions}></img>
                    </div>
                    {/* <div className="sec"> 
                        <p>
                            <b>Summary</b>
                        </p>
                        <br></br>
                        <a>
                            The music inbox makes it convenient for the user to send and listen to shared music. This will also improve the user’s experience by preventing them from forgetting to listen to their friends’ recommended music and better connecting with their friends. The design aims to seamlessly integrate with the existing Spotify app and be intuitive for existing and new users.
                        </a>
                        <br></br>
                        <br></br>
                    </div> */}
                </div>
        )
    }
}

export default Lighten