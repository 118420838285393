import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './duckCamo.css';
import {SRLWrapper} from "simple-react-lightbox";
// import red1 from './nike/red1.jpg';




export class DuckCamo extends Component {
    render() {
        return (
                <div className="gallery">
                    <SRLWrapper>
                        {/* <img id="red1" src={red1}></img> */}
 
                    </SRLWrapper>
                </div>
        )
    }
}

export default DuckCamo