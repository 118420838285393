import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './hawaii.css';
import {SRLWrapper} from "simple-react-lightbox";
import hi1 from './hawaii/hi1.jpg';
import hi2 from './hawaii/hi2.jpg';
import hi3 from './hawaii/hi3.jpg';
import hi4 from './hawaii/hi4.jpg';
import hi5 from './hawaii/hi5.jpg';
import hi6 from './hawaii/hi6.jpg';
import hi7 from './hawaii/hi7.jpg';
import hi8 from './hawaii/hi8.jpg';
import hi9 from './hawaii/hi9.jpg';
import hi10 from './hawaii/hi10.jpg';
import hi11 from './hawaii/hi11.jpg';
import hi12 from './hawaii/hi12.jpg';
import hi13 from './hawaii/hi13.jpg';
import hi14 from './hawaii/hi14.jpg';
import hi15 from './hawaii/hi15.jpg';
import hi16 from './hawaii/hi16.jpg';
import hi17 from './hawaii/hi17.jpg';
import hi18 from './hawaii/hi18.jpg';
import hi19 from './hawaii/hi19.jpg';
import hi20 from './hawaii/hi20.jpg';
import hi21 from './hawaii/hi21.jpg';
import hi22 from './hawaii/hi22.jpg';
import hi23 from './hawaii/hi23.jpg';




export class Hawaii extends Component {
    render() {
        return (
                <div className="gallery">
                    <SRLWrapper>
                        <img id="hi1" src={hi1}></img>
                        <img id="hi2" src={hi2}></img>
                        <img id="hi3" src={hi3}></img>
                        <img id="hi4" src={hi4}></img>
                        
                        <img id="hi7" src={hi7}></img>
                        <img id="hi8" src={hi8}></img>
                        <img id="hi9" src={hi9}></img>
                        <img id="hi10" src={hi10}></img>
                        <img id="hi11" src={hi11}></img>
                        
                        
                        <img id="hi15" src={hi15}></img>
                        <img id="hi21" src={hi21}></img>
                        <img id="hi16" src={hi16}></img>
                        <img id="hi17" src={hi17}></img>
                        <img id="hi18" src={hi18}></img>
                        
                        <img id="hi20" src={hi20}></img>
                        
                        <img id="hi22" src={hi22}></img>
                        
                        <img id="hi12" src={hi12}></img>
                        <img id="hi13" src={hi13}></img>
                        <img id="hi19" src={hi19}></img>
                        <img id="hi14" src={hi14}></img>
                        <img id="hi5" src={hi5}></img>
                        <img id="hi6" src={hi6}></img>
                        <img id="hi23" src={hi23}></img>
                    </SRLWrapper>
                </div>
        )
    }
}

export default Hawaii